import { Loader2, X } from "lucide-react";
import { toast } from "sonner";
import type { ExternalToast } from "sonner";
import { cn } from "~/util/css";

const toastSettings = { duration: 3000, position: "bottom-right" };

interface LoadingToastProps {
  text?: string;
  title?: string;
  t: any;
}

export function LoadingToast(props: LoadingToastProps) {
  return (
    <BaseToast
      {...props}
      variant="loading"
      icon={<Loader2 size={16} className="animate-spin" />}
      title={props.title}
    />
  );
}

interface LoadingToastInput {
  text?: string;
  id: any;
  duration?: number;
  action?: any;
}

export function loading(input: LoadingToastInput) {
  const instance = toast.custom(
    (t) => <LoadingToast t={t} text={input.text} />,
    {
      position: toastSettings.position,
      duration: input.duration ?? toastSettings.duration,
      action: input.action,
      id: input.id,
    } as ExternalToast,
  );
  return instance;
}

interface DismissToastInput {
  id: any;
}

export function dismiss(input: DismissToastInput) {
  return toast.dismiss(input.id);
}

interface FailToastProps {
  text?: string;
  title?: string;
  action?: any;
  t: any;
}

export function FailToast(props: FailToastProps) {
  return (
    <BaseToast
      {...props}
      variant="fail"
      icon={<img src="/icons/fail.svg" alt="fail" className="w-5 h-5" />}
      title={props.title}
      action={props.action}
    />
  );
}

interface FailToastInput {
  text?: string;
  title?: string;
  action?: any;
  duration?: number;
  id?: any;
}

export function fail(input: FailToastInput) {
  toast.custom(
    (t) => (
      <FailToast
        t={t}
        text={input.text}
        title={input.title}
        action={input.action}
      />
    ),
    {
      ...toastSettings,
      duration: input.duration ?? toastSettings.duration,
      id: input.id,
    } as ExternalToast,
  );
}

interface OkToastProps {
  text?: string;
  title?: string;
  action?: any;
  t: any;
}

export function OkToast(props: OkToastProps) {
  return (
    <BaseToast
      {...props}
      variant="ok"
      icon={
        <img src="/icons/check_circle_light.svg" alt="ok" className="w-5 h-5" />
      }
      title={props.title}
      action={props.action}
    />
  );
}

interface okToastInput {
  title?: string;
  text?: string;
  action?: any;
  id?: string;
}

export function ok(input: okToastInput) {
  toast.custom(
    (t) => (
      <OkToast
        t={t}
        text={input.text}
        title={input.title}
        action={input.action}
      />
    ),
    {
      ...toastSettings,
      id: input.id,
    } as ExternalToast,
  );
}

export function InfoToast(props: OkToastProps) {
  return (
    <BaseToast
      {...props}
      variant="info"
      icon={<img src="/icons/info.svg" alt="info" className="w-5 h-5" />}
      title={props.title}
      action={props.action}
    />
  );
}

interface infoToastInput {
  title?: string;
  text?: string;
  action?: any;
  id?: string;
}

export function info(input: infoToastInput) {
  toast.custom(
    (t) => (
      <InfoToast
        t={t}
        text={input.text}
        title={input.title}
        action={input.action}
      />
    ),
    {
      ...toastSettings,
      id: input.id,
    } as ExternalToast,
  );
}

interface BaseToastProps {
  title?: string;
  text?: string;
  t: any;
  variant: "ok" | "fail" | "info" | "loading";
  icon: React.ReactNode;
  action?: any;
}

function BaseToast(props: BaseToastProps) {
  return (
    <div
      className={cn(
        "flex border-2 border-black rounded-xl min-w-[245px] ml-[30px] shadow-[4px_4px_0px_rgba(0,0,0,1)] overflow-hidden",
        {
          "bg-[linear-gradient(180deg,#FFC7C5_0%,#FFF2F1_100%)]":
            props.variant === "fail",
          "bg-[linear-gradient(180deg,#F2F8FF_0%,#DDFFF3_100%)]":
            props.variant === "ok",
          "bg-[linear-gradient(180deg,#FFFDE7_0%,#FFF9C4_100%)]":
            props.variant === "loading",
          "bg-[linear-gradient(180deg,#CBE3FF_0%,#EAF4FF_100%)]":
            props.variant === "info",
        },
      )}
    >
      <div
        className={cn("w-3 h-auto", {
          "bg-[#FE3B70]": props.variant === "fail",
          "bg-[#6BD6B2]": props.variant === "ok",
          "bg-[#f7c87b]": props.variant === "loading",
          "bg-[#337DD4]": props.variant === "info",
        })}
      />
      <div className="flex items-center flex-1 px-3 py-4 gap-2">
        {props.icon}
        <div className="flex-1 text-sm">
          {props.title && <p className="font-medium">{props.title}</p>}
          {props.text && <p>{props.text}</p>}
        </div>
        {props.action && (
          <button
            type="button"
            onClick={() => {
              props.action.onClick();
              toast.dismiss(props.t);
            }}
            className={cn(
              "h-auto text-white shadow-lg p-2 rounded-xl border-2 border-black text-sm",
              {
                "bg-[#FE3B70]": props.variant === "fail",
                "bg-[#6BD6B2]": props.variant === "ok",
                "bg-[#f7c87b]": props.variant === "loading",
                "bg-[#337DD4]": props.variant === "info",
              },
            )}
          >
            {props.action.label}
          </button>
        )}
        <button type="button" onClick={() => toast.dismiss(props.t)}>
          <X size={18} />
        </button>
      </div>
    </div>
  );
}
